import request from '@/utils/request'

export function getCampusArticle(parameter) {
  return request({
    url: '/campus/article',
    method: 'get',
    params: parameter
  })
}

export function queryCampusArticles(parameter) {
  return request({
    url: '/campus/article',
    method: 'post',
    data: parameter
  })
}

export function removeCampusArticle(parameter) {
  return request({
    url: '/campus/article',
    method: 'delete',
    params: parameter
  })
}

export function putCampusArticle(parameter) {
  return request({
    url: '/campus/article',
    method: 'put',
    data: parameter
  })
}

export function upstateCampusArticle(parameter) {
  return request({
    url: '/campus/article/status',
    method: 'post',
    data: parameter
  })
}

export function stickyCampusArticle(parameter) {
  return request({
    url: '/campus/article/sticky',
    method: 'post',
    data: parameter
  })
}

export function getCampusSubject(parameter) {
  return request({
    url: '/campus/subject',
    method: 'get',
    params: parameter
  })
}

export function queryCampusSubjects(parameter) {
  return request({
    url: '/campus/subject',
    method: 'post',
    data: parameter
  })
}

export function putCampusSubject(parameter) {
  return request({
    url: '/campus/subject',
    method: 'put',
    data: parameter
  })
}

export function deleteCampusSubject(parameter) {
  return request({
    url: '/campus/subject',
    method: 'delete',
    params: parameter
  })
}



